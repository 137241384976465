// import ru from './lang/ru-RU.yaml';
// import cz from './lang/cz-CZ.yaml';
// import en from './lang/en.yaml';

export default {
  // strategy: 'prefix_except_default',
  legacy: false,
  // locale: 'en',
  // fallbackLocale: 'ru',
  // messages: {
  //   ru: ru,
  //   cz: cz,
  //   en: en,
  // },
  // detectBrowserLanguage: false,
  // warnHtmlMessage: false,
  postTranslation: str => {
    if (typeof str === 'string') return str.replace(/&nbsp;/g, '\u00A0');
    else return str;
  },
  // compilation: {
  //   jit: true,
  //   strictMessage: false,
  //   escapeHtml: false,
  // },
};
